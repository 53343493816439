import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import NotFound from '@/views/NotFound.vue'

import SignUp from '@/views/SignUp.vue'
import SignIn from '@/views/SignIn.vue'
import SignOut from '@/views/SignOut.vue'

import Recovery from '@/views/Recovery.vue'
import RecoveryRequest from '@/views/Recovery/RecoveryRequest.vue'
import RecoveryForm from '@/views/Recovery/RecoveryForm.vue'

import Overview from '@/views/Overview.vue'

const redirect = { template: "<p>Redirection</p>" }

const routes: Array<RouteRecordRaw> = [
	{
		path: '/signup',
		name: 'SignUp',
		component: SignUp,
		meta: { requiresAuth: false }
	},
	{
		path: '/signin',
		name: 'SignIn',
		component: SignIn,
		meta: { requiresAuth: false }
	},
	{ path: '/', redirect: '/signin' },
	{
		path: '/signout',
		name: 'SignOut',
		component: SignOut,
		meta: { requiresAuth: true }
	},
	{
		path: '/recovery',
		name: 'Recovery',
		component: Recovery,
		meta: { requiresAuth: false },
		children: [
			{
				path: '',
				name: 'RecoveryRequest',
				component: RecoveryRequest,
				meta: { requiresAuth: false }
			},
			{
				path: ':uuid/:recoverycode',
				name: 'RecoveryForm',
				component: RecoveryForm,
				meta: { requiresAuth: false }
			},
		]
	},

	{
		path: '/overview',
		name: 'Overview',
		component: Overview,
		meta: { requiresAuth: true }
	},

	{
		path: '/:pathMatch(.*)*',
		name: "NotFound",
		component: NotFound,
		meta: { requiresAuth: false }
	},

	{
		path: '/redirect/www',
		beforeEnter() { location.href = 'https://devloader.fr' },
		component: redirect,
		meta: { requiresAuth: false }
	},

	{
		path: '/redirect/maintenance',
		beforeEnter() { location.href = 'https://devloader.fr/maintenance' },
		component: redirect,
		meta: { requiresAuth: false }
	},
	{
		path: '/maintenance',
		redirect: '/redirect/maintenance'
	},

	{
		path: '/redirect/contact',
		beforeEnter() { location.href = 'https://devloader.fr/contact' },
		component: redirect,
		meta: { requiresAuth: false }
	}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
