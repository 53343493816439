
import { Options, Vue } from 'vue-class-component'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import FAIcon from '@/components/Utils/FAIcon.vue'

import LoaderDots from '@/components/UI/LoaderDots.vue'

import axios, { AxiosError, AxiosResponse } from "axios"
import { APISignUpResponse } from '@/components/Utils/FormClasses.vue'

@Options({
    components: {
        FontAwesomeIcon,
        LoaderDots
    }
})
export default class RecoveryRequest extends Vue {
    pageTitle = "Récupération du mot de passe - Etape 1/2"

    username = ""

    errorMsg = ""
    infoMsg = ""

    showLoader = false

	icons = {
		'email': FAIcon.Instantiate('fas', 'at'),
        'signin': FAIcon.Instantiate('fas', 'angle-left'),
		'signup': FAIcon.Instantiate('fas', 'user-plus'),
	}

    /*beforeCreate() : void {
        if(localStorage.getItem(this.$store.state.auth.keyname))
            this.$router.push(this.$store.state.auth.homeUri)
        else
            this.$router.push(this.$store.state.auth.signinUri)
    }*/

    mounted(): void {
        document.title = this.pageTitle
    }

    GoTo(uri: string): boolean {
        this.$router.push(uri)
        return false
    }

    SendRecoveringEmail() : boolean {
        if(this.username == '')
            return false

        this.errorMsg = ''
        this.infoMsg = ''

        const uri = this.$store.state.api.baseUrl + '/recovery?username=' + encodeURIComponent(this.username)

        this.showLoader = true

        axios.get(uri).then((response: AxiosResponse) => {
            this.showLoader = false

            const apiResponse  = response.data as APISignUpResponse

            if(apiResponse.content.message)
                this.infoMsg = apiResponse.content.message
        }).catch((reason: AxiosError) => {
            this.showLoader = false

            if(reason.response)
            {
                const apiResponse = reason.response.data as APISignUpResponse

                if(apiResponse.content && apiResponse.content.error)
                    this.errorMsg = apiResponse.content.error.message
                else
                    this.errorMsg = reason.message
            }
            else
                this.errorMsg = "Veuillez vérifier votre connexion à Internet"
        })

        return false
    }
}
