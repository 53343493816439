
import APIResponse, { APIResult } from '@/components/Utils/APIClasses.vue'

export type OnStateChangedHandler = (data: StateData) => void

export class ResourceState {
    static error = "error"
    static found = "correct"
    static notfound = "unknown"
}

export class ResourceResult {
    state = ""
    message = ""
}

export class APISignUpResult extends APIResult {
	username!: ResourceResult
	email!: ResourceResult
	password!: ResourceResult
    
	companyname!: ResourceResult
	companyaddress!: ResourceResult
	siret!: ResourceResult
	tva!: ResourceResult

    authtoken!: string
    message!: string
}

export class APISignUpResponse extends APIResponse {
    declare content: APISignUpResult
}

export default class StateData {
    state = false
    formdata: FormData|null = null

    constructor(state: boolean, formdata: FormData|null)
    {
        this.state = state
        this.formdata = formdata
    }

    static Instantiate(state: boolean, formdata: FormData|null) : StateData
    { return new StateData(state, formdata) }
}
