
export class APIError {
	code = ""
	message = ""
}

export class APIResult {
	error!: APIError
}

export default class APIResponse {
	result = 0
	content!: APIResult
}
