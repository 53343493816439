import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b71200e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "MailAddress" }
const _hoisted_2 = { href: "mailto:contact@devloader.fr" }
const _hoisted_3 = { class: "version" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("footer", {
    style: _normalizeStyle(_ctx.CSSVars)
  }, [
    _createElementVNode("address", null, [
      _createElementVNode("p", _hoisted_1, [
        _createElementVNode("a", _hoisted_2, [
          _createVNode(_component_font_awesome_icon, { icon: [ 'fas', 'envelope' ] }),
          _createTextVNode("contact@devloader.fr")
        ])
      ])
    ]),
    _createElementVNode("p", _hoisted_3, "Version " + _toDisplayString(_ctx.GetVersion()), 1)
  ], 4))
}