
import { Options, Vue } from 'vue-class-component'

@Options({
    props: {
        id: {
            type: String
        },

        type: {
            type: String,
            default: "block"
        },
        
        color: {
            type: String,
            default: "black"
        }
    }
})
export default class LoaderDots extends Vue {

}

