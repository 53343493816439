
import { Options, Vue } from 'vue-class-component';

@Options({})

export default class NotFound extends Vue {
	pageTitle = "404 - Page introuvable"
	previousPath = "/"

	mounted() : void {
		document.title = this.pageTitle
		this.previousPath = this.$store.state.app.previousPath
	}
}
