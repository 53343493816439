
import { Options, Vue } from 'vue-class-component'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import FAIcon from '@/components/Utils/FAIcon.vue'

import axios, { AxiosError, AxiosResponse } from "axios"
import StateData, { APISignUpResponse } from '@/components/Utils/FormClasses.vue'

import LoaderDots from '@/components/UI/LoaderDots.vue'

import SignUpAccount from '@/components/SignUpParts/SignUpAccount.vue'
import SignUpProfile from '@/components/SignUpParts/SignUpProfile.vue'
import SignUpCompany from '@/components/SignUpParts/SignUpCompany.vue'

@Options({
    components: {
        FontAwesomeIcon,
        LoaderDots,
        SignUpAccount,
        SignUpProfile,
        SignUpCompany
    },

    computed: {
        IsFormCompanyAsked(): boolean {
            return this.showCompany = this.showProfile && this.showCompany && this.showButtons
        }
    }
})
export default class SignUp extends Vue {
    pageTitle = "Inscription client"

    showProfile = false
    showCompany = false

    showButtons = false

    accountCompleted = false
    profileCompleted = false
    companyCompleted = false

    accountFormData: FormData|null = null
    profileFormData: FormData|null = null
    companyFormData: FormData|null = null

    isSignUping = false
    isAccountCreated = false
    isCompanyCreated = false

    accountError = ""
    companyError = ""

    icons = {
        'signin': FAIcon.Instantiate('fas', 'angle-left'),
		'forgotten': FAIcon.Instantiate('fas', 'key'),
    }

    mounted(): void {
        document.title = this.pageTitle
    }

    ShowProfile(data: StateData): void {
        this.accountCompleted = data.state

        this.showProfile = data.state
        this.accountFormData = data.formdata

        if(!data.state)
            this.showButtons = false
        else
            this.showButtons = this.profileCompleted
    }

    ShowCompany(): boolean {
        this.showCompany = true
        return false
    }

    EnableButtons(data: StateData): void {
        this.profileCompleted = data.state
        this.showButtons = data.state

        this.profileFormData = data.formdata
    }

    OnCompanyFormCompletedHandler(data: StateData): void {
        this.companyCompleted = data.state
        this.companyFormData = data.formdata
    }

    SendFormDatas(): boolean {
        if(!(this.accountCompleted && this.profileCompleted))
        {
            this.accountError = "Formulaires incomplets"
            return false
        }
        else if(!(this.accountFormData && this.profileFormData))
        {
            this.accountError = "Formulaires vides"
            return false
        }

        this.isSignUping = true

        const uri = this.$store.state.api.baseUrl + "/signup"
        var formData = new FormData()

        this.accountFormData.forEach((e: string|File, key: string) => {
            formData.append(key, e.toString())
        })

        this.profileFormData.forEach((e: string|File, key: string) => {
            formData.append(key, e.toString())
        })

        axios.post(uri, formData).then((response: AxiosResponse) => {
            this.isSignUping = false
            const apiResponse = response.data as APISignUpResponse

            if(apiResponse.result == 1)
            {
                if(apiResponse.content?.authtoken)
                {
                    localStorage.setItem(this.$store.state.auth.keyname, apiResponse.content.authtoken)
                    this.isAccountCreated = true

                    if(this.companyCompleted)
                        this.SendCompanyData()
                    else
                        this.$router.push(this.$store.state.auth.redirectUri)
                }
                else
                    this.accountError = "Le serveur retourne 1 mais le token vaut null"
            }
            else
                this.accountError = "Une erreur au moment de l'inscription est survenue"

        }).catch((reason: AxiosError) => {
            this.isSignUping = false
            this.accountError = reason.message
            
            if(reason.response)
                this.accountError += ": " + (reason.response.data as APISignUpResponse).content?.error?.message
        })

        return false
    }

    SendCompanyData(): void {
        if(!this.companyCompleted)
        {
            this.companyError = "Entreprise incomplètes"
            return
        }
        else if(!this.companyFormData)
        {
            this.companyError = "Entreprise vides"
            return
        }

        this.isSignUping = true

        const uri = this.$store.state.api.baseUrl + "/companies"
        var formData = this.companyFormData

        var header = {
            headers: {
                Authorization: 'Bearer <' + localStorage.getItem('auth') + '>'
            }
        }
        
        axios.post(uri, formData, header).then((response: AxiosResponse) => {
            this.isSignUping = false
            const apiResponse = response.data as APISignUpResponse

            if(apiResponse.result == 1)
            {
                if(apiResponse.content?.authtoken)
                {
                    localStorage.setItem(this.$store.state.auth.keyname, apiResponse.content.authtoken)
                    this.isCompanyCreated = true

                    this.$router.push(this.$store.state.auth.redirectUri)
                }
                else
                    this.companyError = "Le serveur retourne 1 mais le token vaut null"
            }
            else
                this.companyError = "Une erreur au moment de l'inscription est survenue"

        }).catch((reason: AxiosError) => {
            this.isSignUping = false

            this.companyError = reason.message
            
            if(reason.response)
                this.companyError += ": " + (reason.response?.data as APISignUpResponse).content?.error?.message
        })
    }

    GoTo(uri: string): boolean {
        this.$router.push(uri)
        return false
    }
}
