
import { Options, Vue } from "vue-class-component"

import { AxiosError, AxiosResponse } from "axios"
import axios from "axios"

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import LoaderDots from '@/components/UI/LoaderDots.vue'

import StateData, { APISignUpResponse, ResourceState } from '@/components/Utils/FormClasses.vue'
import FAIcon from '@/components/Utils/FAIcon.vue'

class SignUpFormData {
    companyname = ""
    companyaddress = ""

    companyemail = ""
    siret = ""
    tva = ""

    GetFormData(): FormData {
        var formData = new FormData()

        formData.append('name', this.companyname)
        formData.append('address', this.companyaddress)
        formData.append('email', this.companyemail)
        formData.append('siret', this.siret)
        formData.append('tva', this.tva)

        return formData
    }

    IsCompleted(): boolean {
        return this.companyname !== "" && this.companyaddress !== "" && this.companyemail !== "" && this.siret !== "" && this.tva !== ""
    }
}

class FormFieldStates {
    companyname = ""
    companyaddress = ""

    companyemail = ""
    siret = ""
    tva = ""

    IsCompleted() : boolean {
        return this.companyname == ResourceState.notfound 
            && this.companyaddress == ResourceState.notfound 
            && this.companyemail == ResourceState.notfound 
            && this.siret == ResourceState.notfound 
            && this.tva == ResourceState.notfound 
    }
}

@Options({
    props: {
        id: String,
        companyemail: {
            type: String,
            default: ""
        },
        error: {
            type: String,
            default: ""
        }
    },
    components: {
        FontAwesomeIcon,
        LoaderDots
    },
    computed: {
        ErrorState() {
            return ResourceState.error
        },
        FoundState() {
            return ResourceState.found
        },
        NotFoundState() {
            return ResourceState.notfound
        }
    }
})
export default class SignUpCompany extends Vue {
    companyemail!: string

    formData = new SignUpFormData()

    errors = {
        companyname: "",
        companyaddress: "",
        companyemail: "",
        siret: "",
        tva: ""
    }

    error!: string

    states = new FormFieldStates()

	icons = {
		'companyname': FAIcon.Instantiate('fas', 'signature'),
		'companyaddress': FAIcon.Instantiate('fas', 'envelope'),
		'companyemail': FAIcon.Instantiate('fas', 'at'),
		'siret': FAIcon.Instantiate('fas', 'address-card'),
		'tva': FAIcon.Instantiate('fas', 'receipt'),

		'check': FAIcon.Instantiate('fas', 'check'),
        'error': FAIcon.Instantiate('fas', 'exclamation-triangle'),
		'retry': FAIcon.Instantiate('fas', 'undo'),
	}

    mounted(): void {
        this.formData.companyemail = this.companyemail
    }

    ClearState(state: string) {
        if(state === 'companyname')
            this.states.companyname = ''
        else if(state === 'companyaddress')
            this.states.companyaddress = ''
        else if(state === 'companyemail')
            this.states.companyemail = ''
        else if(state === 'siret')
            this.states.siret = ''
        else if(state === 'tva')
            this.states.tva = ''

        this.ValidateForm()
    }

    SubmitCompanyName(): void {
        if(this.formData.companyname === "")
        {
            this.states.companyname = ""
            this.errors.companyname = ""
            return
        }

        this.states.companyname = "waitin"
        this.errors.companyname = ""

        const uri = this.$store.state.api.baseUrl + "/signup?name=" + encodeURIComponent(this.formData.companyname)
        axios.get(uri).then((response: AxiosResponse) => {
            const apiResponse = response.data as APISignUpResponse
            this.states.companyname = ResourceState.error

            if(!apiResponse.content)
                this.errors.companyname = "Le serveur n'a pas envoyé de réponse"
            else if(apiResponse.content.error)
                this.errors.companyname = apiResponse.content.error.message
            else if(apiResponse.content.companyname)
            {
                 this.states.companyname = apiResponse.content.companyname.state

                if(this.states.companyname != ResourceState.notfound)
                    this.errors.companyname = apiResponse.content.companyname.message
            }
            else
                this.errors.companyname = "Impossible d'obtenir une réponse du serveur"

            this.ValidateForm()
        }).catch((reason: AxiosError) => {
            this.states.companyname = ResourceState.error

            if(reason.response)
            {
                const apiResponse = reason.response.data as APISignUpResponse

                if(apiResponse.content && apiResponse.content.error)
                    this.errors.companyname = apiResponse.content.error.message
                else
                    this.errors.companyname = reason.message
            }
            else
                this.errors.companyname = "Veuillez vérifier votre connexion à Internet"

            this.ValidateForm()
        })
    }

    SubmitEmail(): void {
        if(this.formData.companyemail === "")
        {
            this.states.companyemail = ""
            this.errors.companyemail = ""
            return
        }

        this.states.companyemail = "waitin"
        this.errors.companyemail = ""

        const uri = this.$store.state.api.baseUrl + "/signup?email=" + encodeURIComponent(this.formData.companyemail)
        axios.get(uri).then((response: AxiosResponse) => {
            const apiResponse = response.data as APISignUpResponse
            this.states.companyemail = ResourceState.error

            if(!apiResponse.content)
                this.errors.companyemail = "Le serveur n'a pas envoyé de réponse"
            else if(apiResponse.content.error)
                this.errors.companyemail = apiResponse.content.error.message
            else if(apiResponse.content.email)
            {
                 this.states.companyemail = apiResponse.content.email.state

                if(this.states.companyemail != ResourceState.notfound)
                    this.errors.companyemail = apiResponse.content.email.message
            }
            else
                this.errors.companyemail = "Impossible d'obtenir une réponse du serveur"

            this.ValidateForm()
        }).catch((reason: AxiosError) => {
            this.states.companyemail = ResourceState.error

            if(reason.response)
            {
                const apiResponse = reason.response.data as APISignUpResponse

                if(apiResponse.content && apiResponse.content.error)
                    this.errors.companyemail = apiResponse.content.error.message
                else
                    this.errors.companyemail = reason.message
            }
            else
                this.errors.companyemail = "Veuillez vérifier votre connexion à Internet"

            this.ValidateForm()
        })
    }

    SubmitCompanyAddress(): void {
        if(this.formData.companyaddress === "")
        {
            this.states.companyaddress = ""
            this.errors.companyaddress = ""
            return
        }

        this.states.companyaddress = "waitin"
        this.errors.companyaddress = ""

        const uri = this.$store.state.api.baseUrl + "/signup?address=" + encodeURIComponent(this.formData.companyaddress)
        axios.get(uri).then((response: AxiosResponse) => {
            const apiResponse = response.data as APISignUpResponse
            this.states.companyaddress = ResourceState.error

            if(!apiResponse.content)
                this.errors.companyaddress = "Le serveur n'a pas envoyé de réponse"
            else if(apiResponse.content.error)
                this.errors.companyaddress = apiResponse.content.error.message
            else if(apiResponse.content.companyaddress)
            {
                 this.states.companyaddress = apiResponse.content.companyaddress.state

                if(this.states.companyaddress != ResourceState.notfound)
                    this.errors.companyaddress = apiResponse.content.companyaddress.message
            }
            else
                this.errors.companyaddress = "Impossible d'obtenir une réponse du serveur"

            this.ValidateForm()
        }).catch((reason: AxiosError) => {
            this.states.companyaddress = ResourceState.error

            if(reason.response)
            {
                const apiResponse = reason.response.data as APISignUpResponse

                if(apiResponse.content && apiResponse.content.error)
                    this.errors.companyaddress = apiResponse.content.error.message
                else
                    this.errors.companyaddress = reason.message
            }
            else
                this.errors.companyaddress = "Veuillez vérifier votre connexion à Internet"

            this.ValidateForm()
        })
    }

    SubmitSIRET(): void {
        if(this.formData.siret === "")
        {
            this.states.siret = ""
            this.errors.siret = ""
            return
        }

        this.states.siret = "waitin"
        this.errors.siret = ""

        const uri = this.$store.state.api.baseUrl + "/signup?siret=" + encodeURIComponent(this.formData.siret)
        axios.get(uri).then((response: AxiosResponse) => {
            const apiResponse = response.data as APISignUpResponse
            this.states.siret = ResourceState.error

            if(!apiResponse.content)
                this.errors.siret = "Le serveur n'a pas envoyé de réponse"
            else if(apiResponse.content.error)
                this.errors.siret = apiResponse.content.error.message
            else if(apiResponse.content.siret)
            {
                 this.states.siret = apiResponse.content.siret.state

                if(this.states.siret != ResourceState.notfound)
                    this.errors.siret = apiResponse.content.siret.message
            }
            else
                this.errors.siret = "Impossible d'obtenir une réponse du serveur"

            this.ValidateForm()
        }).catch((reason: AxiosError) => {
            this.states.siret = ResourceState.error

            if(reason.response)
            {
                const apiResponse = reason.response.data as APISignUpResponse

                if(apiResponse.content && apiResponse.content.error)
                    this.errors.siret = apiResponse.content.error.message
                else
                    this.errors.siret = reason.message
            }
            else
                this.errors.siret = "Veuillez vérifier votre connexion à Internet"

            this.ValidateForm()
        })
    }

    SubmitTVA(): void {
        if(this.formData.tva === "")
        {
            this.states.tva = ""
            this.errors.tva = ""
            return
        }

        this.states.tva = "waitin"
        this.errors.tva = ""

        const uri = this.$store.state.api.baseUrl + "/signup?tva=" + encodeURIComponent(this.formData.tva)
        axios.get(uri).then((response: AxiosResponse) => {
            const apiResponse = response.data as APISignUpResponse
            this.states.tva = ResourceState.error

            if(!apiResponse.content)
                this.errors.tva = "Le serveur n'a pas envoyé de réponse"
            else if(apiResponse.content.error)
                this.errors.tva = apiResponse.content.error.message
            else if(apiResponse.content.tva)
            {
                this.states.tva = apiResponse.content.tva.state

                if(this.states.tva != ResourceState.notfound)
                    this.errors.tva = apiResponse.content.tva.message
            }
            else
                this.errors.tva = "Impossible d'obtenir une réponse du serveur"

            this.ValidateForm()
        }).catch((reason: AxiosError) => {
            this.states.tva = ResourceState.error

            if(reason.response)
            {
                const apiResponse = reason.response.data as APISignUpResponse

                if(apiResponse.content && apiResponse.content.error)
                    this.errors.tva = apiResponse.content.error.message
                else
                    this.errors.tva = reason.message
            }
            else
                this.errors.tva = "Veuillez vérifier votre connexion à Internet"

            this.ValidateForm()
        })
    }

    ValidateForm(): void {
        const completed = this.formData.IsCompleted() && this.states.IsCompleted()
        this.$emit('OnFormCompleted', StateData.Instantiate(completed, completed ? this.formData.GetFormData() : null))
    }
}
