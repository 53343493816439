
export default class FAIcon {
	collection: string
	reference: string

	constructor(collection: string, reference: string) {
		this.collection = collection
		this.reference = reference
	}

	static Instantiate(collection: string, reference: string): FAIcon {
		var faicon = new FAIcon(collection, reference)
		return faicon
	}

    use() : Array<string> {
        return [ this.collection, this.reference ]
    }
}
