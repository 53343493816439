
import { Options, Vue } from 'vue-class-component'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

class CSSVars {
	'--position-name' = "fixed"

	'--txt-color' = "white"
	'--bdr-color' = "gray"
	'--bcg-color' = "#212529"

	'--btn-txt-color' = "white"
	'--btn-txt-active-color' = "#009fe3"

	'--btn-bdr-color' = "white"
	'--btn-bdr-active-color' = "#009fe3"

	'--btn-bcg-color' = "rgba(255,255,255,0.1)"
}

@Options({
	components: {
		FontAwesomeIcon
	},

	computed: {
		CSSVars() {
			return this.cssVars
		},
	}
})

export default class AppFooter extends Vue {
	cssVars = new CSSVars()

	GetVersion(): string {
		return this.$store.state.app.version
	}

	SetBackgroundColor(color: string): void {
		this.cssVars['--bcg-color'] = color
	}
}
