
import { Options, Vue } from 'vue-class-component'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import FAIcon from '@/components/Utils/FAIcon.vue'

import LoaderDots from '@/components/UI/LoaderDots.vue'

import axios, { AxiosError, AxiosResponse } from "axios"
import { APISignUpResponse } from '@/components/Utils/FormClasses.vue'

@Options({
    components: {
        FontAwesomeIcon,
        LoaderDots
    }
})
export default class RecoveryForm extends Vue {
    pageTitle = "Récupération du mot de passe - Etape 2/2"

    password = ""
    confirm = ""

    errorMsg = ""
    infoMsg = ""

    showLoader = false

	icons = {
		'password': FAIcon.Instantiate('fas', 'lock'),
		'forgotten': FAIcon.Instantiate('fas', 'key'),
	}

    mounted(): void {
        document.title = this.pageTitle
    }

    GoTo(uri: string): boolean {
        this.$router.push(uri)
        return false
    }

    SendPassword() : boolean {
        if(this.password == '' || this.confirm == '')
            return false
        else if(this.password !== this.confirm)
        {
            this.errorMsg = 'Les mots de passe ne correspondent pas'
            return false
        }
        
        var regexp = new RegExp(/^.*(?=.{8,})(?=.*\w)(?=.*\d)(?=.*\W).*$/, 'i')

        if(!regexp.test(this.password))
        {
            this.errorMsg = "Le mot de passe ne respecte pas le format imposé"
            return false
        }

        this.errorMsg = ''

        const uri = this.$store.state.api.baseUrl + '/recovery'

        var formData = new FormData()
        formData.append('recovery', this.$route.params.recoverycode as string)
        formData.append('uniqid', this.$route.params.uuid as string)
        formData.append('password', this.password)

        this.showLoader = true

        axios.post(uri, formData).then((response: AxiosResponse) => {
            this.showLoader = false

            const apiResponse  = response.data as APISignUpResponse

            if(apiResponse.result == 1 && apiResponse.content)
            {
                localStorage.setItem(this.$store.state.auth.keyname, apiResponse.content.authtoken)
                this.$router.push(this.$store.state.auth.homeUri)
            }
            else
                this.errorMsg = apiResponse.content.error.message
        }).catch((reason: AxiosError) => {
            this.showLoader = false

            if(reason.response)
            {
                const apiResponse = reason.response.data as APISignUpResponse

                if(apiResponse.content && apiResponse.content.error)
                    this.errorMsg = apiResponse.content.error.message
                else
                    this.errorMsg = reason.message
            }
            else
                this.errorMsg = "Veuillez vérifier votre connexion à Internet"
        })

        return false
    }
}
