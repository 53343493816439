
import { Options, Vue } from 'vue-class-component'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import FAIcon from '@/components/Utils/FAIcon.vue'

import LoaderDots from '@/components/UI/LoaderDots.vue'

import axios, { AxiosError, AxiosResponse } from "axios"
import { APISignUpResponse } from '@/components/Utils/FormClasses.vue'

@Options({
    components: {
        FontAwesomeIcon,
        LoaderDots
    }
})
export default class SignIn extends Vue {
    pageTitle = "Authentification client"

    username = ""
    password = ""

    errorMsg = ""
    showLoader = false

	icons = {
		'username': FAIcon.Instantiate('fas', 'user'),
		'email': FAIcon.Instantiate('fas', 'at'),
		'password': FAIcon.Instantiate('fas', 'lock'),
		'forgotten': FAIcon.Instantiate('fas', 'key'),
		'signup': FAIcon.Instantiate('fas', 'user-plus'),
	}

    /*beforeCreate() : void {
        if(localStorage.getItem(this.$store.state.auth.keyname))
            this.$router.push(this.$store.state.auth.homeUri)
        else
            this.$router.push(this.$store.state.auth.signinUri)
    }*/

    mounted(): void {
        document.title = this.pageTitle

        if(this.$store.state.auth.signinExpired)
            this.errorMsg = "Votre session a expiré, veuillez vous authentifier pour continuer"
    }

    GoTo(uri: string): boolean {
        this.$router.push(uri)
        return false
    }

    SignIn() : boolean {
        if(this.username == '' || this.password == '')
            return false

        const uri = this.$store.state.api.baseUrl + '/signin'

        var formData = new FormData()
        formData.append('username', this.username)
        formData.append('password', this.password)

        this.showLoader = true

        axios.post(uri, formData).then((response: AxiosResponse) => {
            const apiResponse = response.data as APISignUpResponse

            if(apiResponse.result == 1 && apiResponse.content?.authtoken)
            {
                localStorage.setItem(this.$store.state.auth.keyname, apiResponse.content.authtoken)

                if(this.$store.state.auth.signinExpired && this.$route.redirectedFrom)
                    this.$router.push(this.$route.redirectedFrom?.path)
                else
                    this.$router.push(this.$store.state.auth.homeUri)
            }
            else if(apiResponse.content?.error)
                this.errorMsg = apiResponse.content.error.message
            else
                this.errorMsg = "Un problème avec le serveur est survenu"
        }).catch((reason: AxiosError) => {
            this.showLoader = false

            if(reason.response)
            {
                const apiResponse = reason.response.data as APISignUpResponse

                if(apiResponse.content && apiResponse.content.error)
                    this.errorMsg = apiResponse.content.error.message
                else
                    this.errorMsg = reason.message
            }
            else
                this.errorMsg = "Veuillez vérifier votre connexion à Internet"
        })

        return false
    }
}
