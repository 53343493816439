
import FAIcon from '@/components/Utils/FAIcon.vue'

export default class Link
{
	to: string
	label: string
	icon: FAIcon | null

	constructor(to: string, label: string, icon: FAIcon | null = null)
	{
		this.to = to
		this.label = label

		this.icon = icon
	}

	static Instantiate(to: string, label: string, icon: FAIcon | null = null): Link{
		var link = new Link(to, label, icon)
		return link
	}
}
