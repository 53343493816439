
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class SignOut extends Vue {
    pageTitle = "Page de déconnexion"

    mounted(): void {
        document.title = this.pageTitle
    }

    GoTo(uri: string): boolean {
        this.$router.push(uri)
        return false
    }
}
