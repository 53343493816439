
import { Options, Vue } from "vue-class-component"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import StateData from '@/components/Utils/FormClasses.vue'
import FAIcon from '@/components/Utils/FAIcon.vue'

class SignUpFormData {
    firstname = ""
    lastname = ""
    birthday = ""

    GetFormData(): FormData {
        var formData = new FormData()

        formData.append('firstname', this.firstname)
        formData.append('lastname', this.lastname)
        formData.append('birthday', this.birthday)

        return formData
    }

    IsCompleted(): boolean {
        return this.firstname !== "" && this.lastname !== ""
    }
}

@Options({
    props: {
        id: String
    },
    components: {
        FontAwesomeIcon
    }
})
export default class SignUpProfile extends Vue {
    formData = new SignUpFormData()

	icons = {
		'firstname': FAIcon.Instantiate('fas', 'jedi'),
		'lastname': FAIcon.Instantiate('fab', 'galactic-republic'),
		'birthday': FAIcon.Instantiate('fas', 'birthday-cake'),
	}

    ValidateForm(): void {
        this.$emit('OnFormCompleted', StateData.Instantiate(this.formData.IsCompleted(), this.formData.GetFormData()))
    }
}
