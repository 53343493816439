
import { Options, Vue } from 'vue-class-component'

import AppHeader from '@/components/AppParts/AppHeader.vue'
import AppFooter from '@/components/AppParts/AppFooter.vue'

import axios, { AxiosError, AxiosResponse } from "axios"
import { APISignUpResponse } from '@/components/Utils/FormClasses.vue'

@Options({
	components: {
		AppHeader,
		AppFooter
	},
	computed: {
		IsAuthenticated() { return this.isAuthenticated }
	}
})

export default class App extends Vue {
	isInMaintenance = false
	isAuthenticated = false

	appBackgroundColor = "rgb(255, 242, 226)"
	headerBackgroundColor = "#212529"

	mounted() : void {
		this.$store.commit('SetMobileDisplay', window.innerWidth < 1024)

		if(this.isInMaintenance)
			this.$router.push("/maintenance");

		if(document.body.classList.contains("init-fadein"))
		{
			if(!document.body.classList.contains("fadein"))
				window.setTimeout(() => { document.body.classList.add("fadein"); }, 200);
			else
			{
				document.body.classList.remove("fadein");
				window.setTimeout(() => { document.body.classList.add("fadein"); }, 1400);
			}
		}

		var appHeader = this.$refs.AppHeader as AppHeader
		appHeader.SetBackgroundColor(this.headerBackgroundColor)		
		
		var appFooter = this.$refs.AppFooter as AppFooter
		appFooter.SetBackgroundColor(this.headerBackgroundColor)	

		this.$router.beforeEach((to, from, next) => {
			this.$store.commit('SetAppPreviousPath', from.fullPath)

			if(to.path == '/signout')
			{
				this.isAuthenticated = false
				localStorage.removeItem(this.$store.state.auth.keyname)

				next()
				return
			}

			// Si un token est enregistré
			if(localStorage.getItem(this.$store.state.auth.keyname) != null)
			{
				// Vérifie que l'on est bien authentifié
				const uri = this.$store.state.api.baseUrl + '/signin'
				const headers = {
					headers: {
						Authorization: 'Bearer <' + localStorage.getItem(this.$store.state.auth.keyname) + '>'
					}
				}

				axios.get(uri, headers).then((response: AxiosResponse) => {
					const apiResponse = response.data as APISignUpResponse
					this.isAuthenticated = apiResponse.result == 1

					// Si on est bien authentifié
					if(this.isAuthenticated)
					{
						this.$store.commit('SetAuthSigninExpired', false)

						// On met à jour le token
						localStorage.setItem(this.$store.state.auth.keyname, apiResponse.content.authtoken)

						// Si on essaie d'accéder à la page d'authentification ou d'inscription alors qu'on est authentifié on redirige vers l'accueil client
						if(to.path == '/signin' || to.path == '/signup')
							next(this.$store.state.auth.homeUri)
						// Sinon on va où on souhaite aller
						else
							next()
					}
					// Si on est pas authentifié
					else
					{
						// On retire le token
						localStorage.removeItem(this.$store.state.auth.keyname)

						// Si la page à laquelle on veut accéder demande une authentification on redirige vers la page d'authentification client
						if (to.matched.some(record => record.meta.requiresAuth))
							next(this.$store.state.auth.signinUri)
						// Sinon on va où on souhaite aller
						else
							next()
					}
				}).catch((reason: AxiosError) => {
					// En cas de problème avec le serveur on déconnecte l'utilisateut
					this.isAuthenticated = false
					localStorage.removeItem(this.$store.state.auth.keyname)

					// Si la page demande une authentification on redirige vers l'authentification client
					if (to.matched.some(record => record.meta.requiresAuth))
					{
						if(reason.response)
						{
							const apiResponse = reason.response.data as APISignUpResponse

							if(apiResponse.content.error.code == "expired-token")
								this.$store.commit('SetAuthSigninExpired', true)
						}

						next(this.$store.state.auth.signinUri)
					}
					// Sinon on continue
					else
						next()
				})
			}
			// S'il n'y a pas de token
			else
			{
				this.isAuthenticated = false

				// Si la page demande une authentification on redirige vers l'authentification client
				if (to.matched.some(record => record.meta.requiresAuth))
					next(this.$store.state.auth.signinUri)
				// Sinon on continue
				else
					next()
			}
		});
	}

	SetAuthenticated(isAuthenticated: boolean) : void {
		this.isAuthenticated = isAuthenticated
	}

	static _(id: string) : HTMLElement | null { return document.getElementById(id); }
}
