
import { Options, Vue } from 'vue-class-component'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import FAIcon from '@/components/Utils/FAIcon.vue'
import Link from '@/components/Utils/Link.vue'

class CSSVars {
	'--position-name' = "fixed"

	'--txt-color' = "white"
	'--bdr-color' = "gray"
	'--bcg-color' = "#212529"

	'--btn-txt-color' = "white"
	'--btn-txt-active-color' = "#2471BF"

	'--btn-bdr-color' = "white"
	'--btn-bdr-active-color' = "#2471BF"

	'--btn-bcg-color' = "rgba(255,255,255,0.1)"
}

@Options({
	props: {
		isAuthenticated: {
			type: Boolean,
			default: false
		}
	},

	components: {
		FontAwesomeIcon
	},

	computed: {
		CSSVars() {
			return this.cssVars
		},

		IsAuthenticated() {
			return this.isAuthenticated
		}
	}
})

export default class AppHeader extends Vue {
	isAuthenticated!: boolean
	
	linkCSS = {
		'txt-normal-color': "white",
		'txt-hovered-color': '#2471BF'
	}

	icons = {
		'home': FAIcon.Instantiate('fas', 'home'),
		'about': FAIcon.Instantiate('fas', 'address-card'),
		'i2p': FAIcon.Instantiate('fas', 'file-pdf'),
		'create': FAIcon.Instantiate('fas', 'folder-open'),

		'linkedin': FAIcon.Instantiate('fab', 'linkedin-in'),
		'signin': FAIcon.Instantiate('fas', 'sign-in-alt'),
		'signout': FAIcon.Instantiate('fas', 'sign-out-alt'),
		'contact': FAIcon.Instantiate('fas', 'at')
	}

	links = [
		new Link("/", "Mon espace client", this.icons.home),
		//new Link("/about", "A propos", this.icons.about),
		//new Link("/img2pdf", "Image to PDF", this.icons.i2p)
		//new Link("/utils/create", "Scripts", this.icons.create)
	]

	/// CSS Vars
	cssVars = new CSSVars()

	GoTo(uri: string) : boolean {
		this.$router.push(uri)
		return false
	}

	SetBackgroundColor(color: string): void {
		this.cssVars['--bcg-color'] = color
	}
}
