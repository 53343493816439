import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e11e221"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "duo duo1" }
const _hoisted_3 = { class: "duo duo2" }
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "duo duo1" }
const _hoisted_6 = { class: "duo duo2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.type === 'block')
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: _ctx.id,
        class: "loader"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "dot dot-a",
            style: _normalizeStyle({ background: _ctx.color })
          }, null, 4),
          _createElementVNode("div", {
            class: "dot dot-b",
            style: _normalizeStyle({ background: _ctx.color })
          }, null, 4)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "dot dot-a",
            style: _normalizeStyle({ background: _ctx.color })
          }, null, 4),
          _createElementVNode("div", {
            class: "dot dot-b",
            style: _normalizeStyle({ background: _ctx.color })
          }, null, 4)
        ])
      ], 8, _hoisted_1))
    : (_ctx.type === 'inline')
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          id: _ctx.id,
          class: "loader"
        }, [
          _createElementVNode("span", _hoisted_5, [
            _createElementVNode("span", {
              class: "dot dot-a",
              style: _normalizeStyle({ background: _ctx.color })
            }, null, 4),
            _createElementVNode("span", {
              class: "dot dot-b",
              style: _normalizeStyle({ background: _ctx.color })
            }, null, 4)
          ]),
          _createElementVNode("span", _hoisted_6, [
            _createElementVNode("span", {
              class: "dot dot-a",
              style: _normalizeStyle({ background: _ctx.color })
            }, null, 4),
            _createElementVNode("span", {
              class: "dot dot-b",
              style: _normalizeStyle({ background: _ctx.color })
            }, null, 4)
          ])
        ], 8, _hoisted_4))
      : _createCommentVNode("", true)
}