import { createApp } from 'vue'

import App from './App.vue'

import router from './router'
import store from './store'

import { library } from "@fortawesome/fontawesome-svg-core"

import {
    faAddressCard,
    faAngleLeft,
    faArrowRight,
    faAt,
    faBaby,
    faBirthdayCake,
    faCheck,
    faCode,
    faCopy,
    faDatabase,
    faDice,
    faDiceOne,
    faDiceTwo,
    faDotCircle,
    faEnvelope,
    faExclamationTriangle,
    faExpand,
    faFilePdf,
    faFingerprint,
    faFolderOpen,
    faHome,
    faJedi,
    faKey,
    faLock,
    faPaste,
    faPlay,
    faReceipt,
    faSignature,
    faSignInAlt,
    faSignOutAlt,
    faTerminal,
    faUndo,
    faUser,
    faUserPlus,
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faAddressCard,
    faAngleLeft,
    faArrowRight,
    faAt,
    faBaby,
    faBirthdayCake,
    faCheck,
    faCode,
    faCopy,
    faDatabase,
    faDice,
    faDiceOne,
    faDiceTwo,
    faDotCircle,
    faEnvelope,
    faExclamationTriangle,
    faExpand,
    faFilePdf,
    faFingerprint,
    faFolderOpen,
    faHome,
    faJedi,
    faKey,
    faLock,
    faPaste,
    faPlay,
    faReceipt,
    faSignature,
    faSignInAlt,
    faSignOutAlt,
    faTerminal,
    faUndo,
    faUser,
    faUserPlus
)

import {
    faGalacticRepublic,
    faLinkedin,
    faUnity
 } from '@fortawesome/free-brands-svg-icons'

 library.add(
    faGalacticRepublic,
    faLinkedin,
    faUnity
)

createApp(App).use(store).use(router).mount('#app')
