
import { Options, Vue } from "vue-class-component"

import { AxiosError, AxiosResponse } from "axios"
import axios from "axios"

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import LoaderDots from '@/components/UI/LoaderDots.vue'

import StateData, { APISignUpResponse, ResourceState } from '@/components/Utils/FormClasses.vue'
import FAIcon from '@/components/Utils/FAIcon.vue'

class SignUpFormData {
    username = ""
    email = ""

    password = ""
    confirm = ""

    GetFormData(): FormData {
        var formData = new FormData()

        formData.append('username', this.username)
        formData.append('email', this.email)

        formData.append('password', this.password)
        formData.append('confirm', this.confirm)

        return formData
    }

    IsCompleted(): boolean {
        return this.username != "" && this.email != "" && this.password != "" && this.confirm != ""
    }
}

class FormFieldStates {
    username = ""
    email = ""
    password = ""

    IsCompleted() : boolean {
        return this.username == ResourceState.notfound 
            && this.email == ResourceState.notfound 
            && this.password == ResourceState.notfound 
    }
}

@Options({
    props: {
        id: String,
        error: {
            type: String,
            default: ""
        }
    },
    components: {
        FontAwesomeIcon,
        LoaderDots
    },
    computed: {
        ErrorState() {
            return ResourceState.error
        },
        FoundState() {
            return ResourceState.found
        },
        NotFoundState() {
            return ResourceState.notfound
        }
    }
})

export default class SignUpAccount extends Vue {
    formData = new SignUpFormData()

    errors = {
        username: "",
        email: "",
        password: ""
    }

    error!: string

    states = new FormFieldStates()

	icons = {
		'username': FAIcon.Instantiate('fas', 'user'),
		'email': FAIcon.Instantiate('fas', 'at'),
		'password': FAIcon.Instantiate('fas', 'lock'),
		'confirm': FAIcon.Instantiate('fas', ''),

		'check': FAIcon.Instantiate('fas', 'check'),
        'error': FAIcon.Instantiate('fas', 'exclamation-triangle'),
		'retry': FAIcon.Instantiate('fas', 'undo'),
	}

    ClearState(state: string) {
        if(state === 'username')
            this.states.username = ''
        else if(state === 'email')
            this.states.email = ''
        else if(state === 'password')
            this.states.password = ''

        this.ValidateForm()
    }

    SubmitUsername(): void {
        if(this.formData.username === "")
        {
            this.states.username = ""
            this.errors.username = ""
            return
        }

        this.states.username = "waitin"
        this.errors.username = ""

        const uri = this.$store.state.api.baseUrl + "/signup?username=" + encodeURIComponent(this.formData.username)
        axios.get(uri).then((response: AxiosResponse) => {
            const apiResponse = response.data as APISignUpResponse
            this.states.username = ResourceState.error

            if(!apiResponse.content)
                this.errors.username = "Le serveur n'a pas envoyé de réponse"
            else if(apiResponse.content.error)
                this.errors.username = apiResponse.content.error.message
            else if(apiResponse.content.username)
            {
                 this.states.username = apiResponse.content.username.state

                if(this.states.username != ResourceState.notfound)
                    this.errors.username = apiResponse.content.username.message
            }
            else
                this.errors.username = "Impossible d'obtenir une réponse du serveur"

            this.ValidateForm()
        }).catch((reason: AxiosError) => {
            this.states.username = ResourceState.error

            if(reason.response)
            {
                const apiResponse = reason.response.data as APISignUpResponse

                if(apiResponse.content && apiResponse.content.error)
                    this.errors.username = apiResponse.content.error.message
                else
                    this.errors.username = reason.message
            }
            else
                this.errors.username = "Veuillez vérifier votre connexion à Internet"

            this.ValidateForm()
        })
    }

    SubmitEmail(): void {
        if(this.formData.email === "")
        {
            this.states.email = ""
            this.errors.email = ""
            return
        }

        this.states.email = "waitin"
        this.errors.email = ""

        const uri = this.$store.state.api.baseUrl + "/signup?email=" + encodeURIComponent(this.formData.email)
        axios.get(uri).then((response: AxiosResponse) => {
            const apiResponse = response.data as APISignUpResponse
            this.states.email = ResourceState.error

            if(!apiResponse.content)
                this.errors.email = "Le serveur n'a pas envoyé de réponse"
            else if(apiResponse.content.error)
                this.errors.email = apiResponse.content.error.message
            else if(apiResponse.content.email)
            {
                 this.states.email = apiResponse.content.email.state

                if(this.states.email != ResourceState.notfound)
                    this.errors.email = apiResponse.content.email.message
            }
            else
                this.errors.email = "Impossible d'obtenir une réponse du serveur"

            this.ValidateForm()
        }).catch((reason: AxiosError) => {
            this.states.email = ResourceState.error

            if(reason.response)
            {
                const apiResponse = reason.response.data as APISignUpResponse

                if(apiResponse.content && apiResponse.content.error)
                    this.errors.email = apiResponse.content.error.message
                else
                    this.errors.email = reason.message
            }
            else
                this.errors.email = "Veuillez vérifier votre connexion à Internet"

            this.ValidateForm()
        })
    }

    SubmitPassword(): void {
        if(this.formData.password === "")
        {
            this.states.password = ""
            this.errors.password = ""
            return
        }
            
        this.states.password = "waitin"
        this.errors.password = ""
        
        if(this.formData.password !== this.formData.confirm) {
            this.errors.password = "Les mots de passe ne correspondent pas"
            return
        }

        var regexp = new RegExp(/^.*(?=.{8,})(?=.*\w)(?=.*\d)(?=.*\W).*$/, 'i')

        if(regexp.test(this.formData.password))
        {
            this.states.password = ResourceState.notfound
            this.errors.password = ""
        }
        else
        {
            this.states.password = ResourceState.error
            this.errors.password = "Le mot de passe ne respecte pas le format imposé"
        }
        
       this.ValidateForm()
    }

    ValidateForm(): void {
        const completed = this.formData.IsCompleted() && this.states.IsCompleted()
        this.$emit('OnFormCompleted', StateData.Instantiate(completed, completed ? this.formData.GetFormData() : null))
    }
}
