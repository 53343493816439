
import { Options, Vue } from 'vue-class-component'
import LoaderDots from '@/components/UI/LoaderDots.vue'

@Options({
	components: {
		LoaderDots
	}
})

export default class Home extends Vue {
}
