import { createStore } from 'vuex'

export default createStore({
	state: {
		selected: "/",

		app: {
			mobileDisplay: false,
			version: "20221215-2127",
			previousPath: ""
		},

		api: {
			baseUrl: process.env.NODE_ENV === 'production' ? 'https://api.devloader.fr/userspace/v1' : 'http://localhost/userspace/v1', // https://api.olotse.ln/devloader/v1',
		},

		auth: {
			keyname: "auth",
			redirectUri: "/",
			homeUri: "/overview",
			signinUri: "/signin",
			signinExpired: false
		}
	},
	mutations: {
		SetMobileDisplay(state, value) {
			state.app.mobileDisplay = value
		},
		SetAppPreviousPath(state, value) {
			state.app.previousPath = value
		},

		SetAuthSigninExpired(state, value) {
			state.auth.signinExpired = value
		}
	},
	actions: {
	},
	modules: {
	}
})
